<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4" v-if="profile == 3">
        <v-card class="mt-4">
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span class="h6" v-bind="attrs" v-on="on"
                  ><h4>Ajout d’un modèle de dossier (PV d’ouverture, Dossiers d’Appel d’Offres, Demande de Proposition, Rapport d’évaluation)</h4></span
                >
              </template>
              <span>Création/ajout d'un modele de Pv/Rapport</span>
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-model="modele.title"
              name="input"
              type="text"
              label="Saisir le titre du modèle de dossier"
            ></v-text-field>
            <v-autocomplete
              v-model="modele.type"
              :items="this.AllSessionType"
              item-text="object"
              item-value="id"
              outlined
              label="Sélectionner le type de séance en rapport avec le modèle de dossier"
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="addModele" class="white--text" block>
              <v-icon dark>mdi-alpha-m-box</v-icon>Créer le modèle de dossier
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-col>
          <v-card class="mx-auto">
            <v-card-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="h4" v-bind="attrs" v-on="on"
                    ><h4>Liste des modèles de dossiers (PV d’ouverture, Dossiers d’Appel d’Offres, Demande de Proposition, Rapport d’évaluation)</h4></span
                  >
                </template>
                <span>Liste des modèles de dossiers (PV d’ouverture, Dossiers d’Appel d’Offres, Demande de Proposition, Rapport d’évaluation)</span>
              </v-tooltip>
              <v-spacer></v-spacer
              ><v-chip class="ma-2">{{ this.$store.getters["Auth/getModelList"].length }}</v-chip>
            </v-card-title>

            <v-divider class="mt-2"></v-divider>
            <v-virtual-scroll
              :items="this.$store.getters['Auth/getModelList']"
              :item-height="50"
              height="410"
            >
              <template v-slot="{ item }">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon large>mdi-alpha-m-box</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle class="primary--text">{{
                      item.sessionType.description
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      @click="seemeeting(item)"
                      :color="!item.done ? 'red' : ''"
                      depressed
                      small
                    >
                      <span> </span>
                      <v-spacer></v-spacer>
                      <span v-show="item.done">Consulter</span>
                      <span style="color:white" v-show="!item.done">Rediger</span>
                      <v-icon color="grey" right>mdi-eye</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DatePicker from "vue2-datepicker";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import "vue2-datepicker/index.css";
import { mapActions } from "vuex";

export default {
  components: {
    DatePicker
  },
  data: () => ({
    com: {
      file: [],
      com: 0
    },
    session: {
      objet: "",
      location: "",
      start_time: null,
      duration: "",
      password: "",
      document: "",
      notice: ""
    },
    lang: {
      // the locale of formatting and parsing function
      formatLocale: {
        // MMMM
        months: [
          "Janvie",
          "Fevrier",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Decembre"
        ],
        // MMM
        monthsShort: [
          "Jan",
          "Fev",
          "Mar",
          "Avr",
          "Mai",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
        // dddd
        weekdays: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
        // ddd
        weekdaysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        // dd
        weekdaysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        // first day of week
        firstDayOfWeek: 0,
        // first week contains January 1st.
        firstWeekContainsDate: 1,
        // format 'a', 'A'
        meridiem: (h, _, isLowercase) => {
          const word = h < 12 ? "AM" : "PM";
          return isLowercase ? word.toLocaleLowerCase() : word;
        },
        // parse ampm
        meridiemParse: /[ap]\.?m?\.?/i,
        // parse ampm
        isPM: input => {
          return `${input}`.toLowerCase().charAt(0) === "p";
        }
      },
      // the calendar header, default formatLocale.weekdaysMin
      days: [],
      // the calendar months, default formatLocale.monthsShort
      months: [],
      // the calendar title of year
      yearFormat: "YYYY 年",
      // the calendar title of month
      monthFormat: "MMM",
      // the calendar title of month before year
      monthBeforeYear: false
    },

    dynamicCol: 7,
    ComConfig: false,
    Dtype: false,
    sessionIDCom: "",
    AllSessionType: [
      {
        id: 1,
        object: "Séance publique d’ouverture des Offres /Propositions /Plis ou de négociation"
      },
      {
        id: 5,
        object: "Séance publique d’ouverture des Offres/Propositions Financières"
      },
      {
        id: 2,
        object: "Séance d’évaluation des Offres /Propositions /Plis"
      },
      {
        id: 3,
        object: "Séance plénière d'attribution "
      },
      {
        id: 4,
        object: "Séance de négociation"
      }
    ],
    modele: {
      title: "",
      type: 1
    }
  }),
  created() {
    let Virt = [];

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;
    if (this.profile != 3) {
      this.dynamicCol = 12;
    }
    this.$store.dispatch("Auth/loadAllModel");
  },
  watch: {
    Dtype: {
      handler: "clearData"
    }
  },
  methods: {
    ...mapActions({
      extension_addN: "Auth/extension_addN"
    }),
    async getDateDoc() {
      if (this.session.document) {
        let doc = [];
        await this.$store.dispatch("Auth/getDoc", this.session.document);
        doc = this.$store.getters["Auth/get_selected_doc"].document;
        //console.log(doc);
        if (doc.date_opening) {
          this.session.start_time = doc.date_opening;
        } else {
          this.session.start_time = null;
          Swal.fire({
            title: "Attention !",
            text: `La date d'ouverture des offres n'est pas configuree  pour le dossier de concurrence  ${doc.object}`,
            icon: "error"
          });
        }
      }
    },
    clearData() {
      this.session.object = "";
      this.session.location = "";
      this.session.start_time = null;
      this.session.duration = "";
      this.session.password = "";
      this.session.document = "";
      this.session.notice = "";
    },
    async getDateNotice() {
      if (this.session.notice) {
        let notice = [];
        await this.$store.dispatch("Auth/getNotice", this.session.notice);
        notice = this.$store.getters["Auth/get_selected_notice"].notice;
        //console.log(notice);
        if (notice.deadline) {
          this.session.start_time = notice.deadline;
        } else {
          this.session.start_time = null;
          Swal.fire({
            title: "Attention !",
            text: `La date d'ouverture des offres n'est pas configuree  pour l'avis ${notice.object}`,
            icon: "error"
          });
        }
      }
    },
    addModele() {
      const data = new FormData();
      data.append("title", this.modele.title);
      data.append("sesion_type_id", this.modele.type);
      data.append("file_blob", "<p>Lorem ipsum dolorem</p>");
      data.append("is_pv", 1);
      this.$store.dispatch("Auth/addModel", data);
      //this.clearData();
    },
    setComConfig(item) {
      this.ComConfig = !this.ComConfig;
      this.sessionIDCom = item.id;
    },
    comCall() {
      const data = new FormData();
      data.append("commission", this.com.com);
      data.append("session", this.sessionIDCom);
      data.append("file", this.com.file[0]);
      this.$store.dispatch("Auth/comCall", data);

      this.ComConfig = !this.ComConfig;
    },
    seemeeting(item) {
      this.$router.push({ name: "showModel_pv", params: { id: parseInt(item.id) } });
    }
  }
};
</script>
<style>
.vue-daterange-picker {
  width: 100% !important;
}
.mx-datepicker {
  width: 100% !important;
}
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
