<template>
  <v-container fluid>
    <v-row v-show="this.id">
      <v-col cols="12" md="4">
        <v-card class="pa-2">
          <v-card-title>
            <h4>
              TYPE:
              {{
                this.$store.getters["Auth/get_selected_model_pv"].rapportPv.sessionType.description
              }}
            </h4>
            <v-spacer></v-spacer>
            <v-icon
              medium
              :color="
                this.$store.getters['Auth/get_selected_model_pv'].rapportPv.done ? 'green' : 'red'
              "
              >mdi-circle</v-icon
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <b>Date d'ouverture de la séance :</b>
            {{ this.$store.getters["Auth/get_selected_model_pv"].rapportPv.title }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <div>
          <div class="d-flex justify-between">
            <v-btn color="primary" @click.prevent="UpdateModelPv(content)">
              APPLIQUER LES MODIFICATIONS
            </v-btn>
          </div>
          <v-divider></v-divider>
          <el-tiptap
            @onUpdate="onUpdate"
            height="100%"
            class="mt-2"
            width="100%"
            :extensions="extensions"
            :content="this.$store.getters['Auth/get_selected_model_pv'].rapportPv.fileBlob"
            placeholder="Rédaction du Procès-Verbal de contrat/marche"
          >
          </el-tiptap>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  // all extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  ListItem,
  BulletList, // use with ListItem
  OrderedList, // use with ListItem
  TodoItem,
  TodoList, // use with TodoItem
  TextAlign,
  Indent,
  HorizontalRule,
  HardBreak,
  History,
  LineHeight,
  Image,
  TrailingNode,
  Table, // use with TableHeader, TableCell, TableRow
  TableHeader,
  TableCell,
  TableRow,
  TextColor,
  FontSize,
  TextHighlight,
  Preview,
  Fullscreen,
  Print
} from "element-tiptap";

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    userData: "",
    fin: {
      start_time: null,
      selected: []
    },
    extensions: [
      new Doc(),
      new Text(),
      new Paragraph(),
      new Heading({ level: 5 }),
      new Bold({ bubble: true }),
      new Underline({ bubble: true }),
      new Italic({ bubble: true }),
      new Strike({ bubble: true }),
      new Image(),
      new Link({ bubble: true }),
      new TextAlign(),
      new ListItem(),
      new FontSize({
        fontSizes: ["8", "10", "12", "14", "16", "18", "20", "24", "30", "36", "48"]
      }),
      new LineHeight(),
      new TrailingNode(),
      new Table({
        resizable: true,
        bubble: true
      }),
      new TableHeader({ bubble: true }),
      new TableCell({ bubble: true }),
      new TableRow({ bubble: true }),
      new TextColor({ bubble: true }),
      new TextHighlight({ bubble: true }),
      new Preview(),
      new Print({ bubble: true }),
      new BulletList({ bubble: true }),
      new OrderedList({ bubble: true }),
      new TodoItem(),
      new TodoList(),
      new HardBreak(),
      new HorizontalRule({ bubble: true }),
      new Fullscreen({ bubble: true }),
      new History({ bubble: true })
    ],
    com: {
      file: [],
      signed: false,
      com: 0
    },
    docorNoticeID: 0,
    pv: [],
    ppm: [],
    additif: false
  }),
  created() {
    let Virt = [];

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.userData = Virt;
    this.$store.dispatch("Auth/getModelPv", this.id);
  },
  methods: {
    async UpdateModelPv(content) {
      const data = new FormData();
      data.append("file_blob", content);
      data.append("id_model", this.id);
      await this.$store.dispatch("Auth/UpdateModelPv", data);
    },
    async onUpdate(ev) {
      const UpdatedContent = ev;
      // this.synchro = !this.synchro;
      // const data = new FormData();
      // data.append("file_blob", UpdatedContent);
      // data.append("attribution_id", this.attributionID);
      // data.append("isDone", 0);
      // await this.$store.dispatch("Auth/sync_soPV", data).then(()=>{
      //   this.synchro = !this.synchro;
      // });
      this.content = UpdatedContent;
    },
    comCall() {
      const data = new FormData();
      data.append("commission", this.com.com);
      data.append("session", this.id);
      data.append("file", this.com.file[0]);
      this.$store.dispatch("Auth/comCall", data);
    }
  },
  beforeUnmount() {
    this.content = "";
  }
};
</script>
<style lang="scss">
.el-tiptap-editor {
  .el-tiptap-editor__content {
    height: 100vh;
  }
}
/* Basic editor styles */
.ProseMirror {
  margin: 1rem 0;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

/* Table-specific styling */
.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>
