var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(_vm.profile == 3)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"mt-4"},[_c('v-card-title',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"h6"},'span',attrs,false),on),[_c('h4',[_vm._v("Ajout d’un modèle de dossier (PV d’ouverture, Dossiers d’Appel d’Offres, Demande de Proposition, Rapport d’évaluation)")])])]}}],null,false,3389765026)},[_c('span',[_vm._v("Création/ajout d'un modele de Pv/Rapport")])])],1),_c('v-divider'),_c('v-card-text',[_c('v-text-field',{attrs:{"name":"input","type":"text","label":"Saisir le titre du modèle de dossier"},model:{value:(_vm.modele.title),callback:function ($$v) {_vm.$set(_vm.modele, "title", $$v)},expression:"modele.title"}}),_c('v-autocomplete',{attrs:{"items":this.AllSessionType,"item-text":"object","item-value":"id","outlined":"","label":"Sélectionner le type de séance en rapport avec le modèle de dossier"},model:{value:(_vm.modele.type),callback:function ($$v) {_vm.$set(_vm.modele, "type", $$v)},expression:"modele.type"}})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary","block":""},on:{"click":_vm.addModele}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-alpha-m-box")]),_vm._v("Créer le modèle de dossier ")],1)],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-col',[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"h4"},'span',attrs,false),on),[_c('h4',[_vm._v("Liste des modèles de dossiers (PV d’ouverture, Dossiers d’Appel d’Offres, Demande de Proposition, Rapport d’évaluation)")])])]}}])},[_c('span',[_vm._v("Liste des modèles de dossiers (PV d’ouverture, Dossiers d’Appel d’Offres, Demande de Proposition, Rapport d’évaluation)")])]),_c('v-spacer'),_c('v-chip',{staticClass:"ma-2"},[_vm._v(_vm._s(this.$store.getters["Auth/getModelList"].length))])],1),_c('v-divider',{staticClass:"mt-2"}),_c('v-virtual-scroll',{attrs:{"items":this.$store.getters['Auth/getModelList'],"item-height":50,"height":"410"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-alpha-m-box")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))]),_c('v-list-item-subtitle',{staticClass:"primary--text"},[_vm._v(_vm._s(item.sessionType.description))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"color":!item.done ? 'red' : '',"depressed":"","small":""},on:{"click":function($event){return _vm.seemeeting(item)}}},[_c('span'),_c('v-spacer'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.done),expression:"item.done"}]},[_vm._v("Consulter")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!item.done),expression:"!item.done"}],staticStyle:{"color":"white"}},[_vm._v("Rediger")]),_c('v-icon',{attrs:{"color":"grey","right":""}},[_vm._v("mdi-eye")])],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }